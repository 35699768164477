import React from 'react'
import { Helmet } from "react-helmet"
import file from '../static/precios.pdf'
import "../styles/precios.scss"

// Favicons
import favicon16 from "../static/assets/favicon16.png"
import favicon32 from "../static/assets/favicon32.png"
import favicon64 from "../static/assets/favicon64.png"

const Precios = () => {

  return (
    <div className="pdfContainer">
      <Helmet>
         <meta charset="UTF-8"/>
         <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
         <meta http-equiv="X-UA-Compatible" content="ie=edge"/>
         <meta name="robots" content="noindex"/>
         <title>Precios | Goeighta</title>
         <link rel="icon" type="image/png" sizes="16x16" href={`${favicon16}`} />
         <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32}`} />
         <link rel="shortcut icon" type="image/png" href={`${favicon64}`} />
      </Helmet> 
        <object data={file} type="application/pdf" width="100%" height="100%">
            <p>Este navegador no permite la visualizacion de PDFs. Por favor, descargue el archivo PDF para verlo: <a href={file}>Descargar PDF</a>.</p> 
        </object>
    </div>
  );
}

export default Precios